export default function numberFormat(value) {
    if (!value || value === 0) {
        return '0';
    }

    const valType = typeof value;
    const str = valType !== 'string' ? value.toString() : value;

    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
